// GetEmail.js
import React, { useContext } from 'react';
import { Form, Input, Button, Layout, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AssessmentContext } from './AssessmentContext';

const { Header, Content } = Layout;
const { Title } = Typography;

const GetEmail = () => {
    const { setEmail } = useContext(AssessmentContext);
    const navigate = useNavigate();

    const handleEmailSubmit = (values) => {
        setEmail(values.email); // set the email in the context
        navigate("/profile-result");
    };

    return (
        <>

            <Layout>
                <Header style={{ background: '#fff', textAlign: 'center', padding: '0' }}>
                    <Title level={2}>Get Your Full Profile</Title>
                </Header>
                <Content style={{ margin: '24px 16px 0', overflow: 'initial', textAlign: 'center' }}>
                    <div style={{ padding: 24, background: '#fff', textAlign: 'center' }}>
                        <Title level={4}>Enter your email and get your leadership self-assessment full analysis directly in your Inbox</Title>
                        <Form onFinish={handleEmailSubmit}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    }
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>

                    </div>
                </Content>
            </Layout>
        </>
    );
};

export default GetEmail;