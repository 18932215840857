import React, { createContext, useState } from 'react';

export const AssessmentContext = createContext();

export const AssessmentProvider = ({ children }) => {
    
    const [email, setEmail] = useState("");
    const [answers, setAnswers] = useState({});

    const setAnswer = (questionId, answer, category) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: { answer, category }
        }));
    };

    const contextValue = {
        email,
        setEmail,
        answers,
        setAnswers,
        setAnswer
    };

    return (
        <AssessmentContext.Provider value={contextValue}>
            {children}
        </AssessmentContext.Provider>
    );
};
