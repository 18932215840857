import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;
const { Paragraph } = Typography;

const WelcomePage = () => {
    return (
        <Layout>

            <Content style={{ background: '#fff', textAlign: 'center', margin: '16px 16px 0px 16px' }}>
                <Title level={2}>Welcome to your AI-based Leadership Self-Assessment</Title>
            </Content>

            <Content style={{ margin: '16px 16px 0', overflow: 'initial', textAlign: 'center' }}>
                <div style={{ padding: 12, background: '#fff', textAlign: 'center' }}>
                    <div style={{ padding: 12, background: '#fff', textAlign: 'center', maxWidth: '440px', margin: 'auto', display: 'block' }}>
                        
                        <Paragraph style={{ textAlign: 'center'}}>
                            <Title level={4}>Click the button below to start the leadership self-assessment</Title>
                        </Paragraph>

                        <Link to="/assessment">
                            <Button type="primary" size="large">Start</Button>
                        </Link>

                        <Paragraph style={{ textAlign: 'justify', padding:'12px 0px 0px 0px'}}>
                            Your self-assessment should take no more than 1 to 2 minutes. {<br />}{<br />}

                            We of course safeguard your privacy and will treat your data with 100% confidentiality. Nobody except you will receive your data or your self-assessment. {<br />}{<br />}

                            After submitting your self-assessment, you will receive by email your complimentary personal leadership profile and diagnostic of your leadership strengths, gaps, areas of possible growth, and recommendations for development—all tailored to you. {<br />}{<br />}

                            If you do not see the email and PDF file from Leaders Academy Online with your personal leadership profile in your inbox within 1 hour of submitting your self-assessment, check your Spam folder. {<br />}{<br />}

                            If you still don’t find the email, please contact us at <a href="mailto:support@leadersacademy.online">support@leadersacademy.online</a>. {<br />}{<br />}

                            Thank you.

                        </Paragraph>

                        <Link to="/assessment">
                            <Button type="primary" size="large">Start</Button>
                        </Link>


                    </div>

                </div>
            </Content>
        </Layout>
    );
};

export default WelcomePage;
