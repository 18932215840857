import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Select, Typography, Divider, Table, Tag, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AssessmentContext } from './AssessmentContext';
 
const { Option } = Select;
const { Title } = Typography;

const questionsByCategory = {
   "Self-Leadership": [
        "Being responsible (author, not victim)",
        "Staying authentic, true to your values",
        "Checking your own biases, assumptions, blindspots, motives, values",
        "Staying centered in the heat of the action",
        "Keeping a beginner’s mind, humility",
        "Regulating your emotions",
        "Embracing your fears, vulnerability",
        "Showing courage",
        "Being open to coaching",
        "Keeping Integrity (walking the talk, leading by example)",
        "Resolving ethical dilemmas",
        "Decoding other cultures or value systems"
    ],
    "Relationship / Communication": [
        "Building and deepening relationships",
        "Building trust",
        "Speaking powerfully, straight talk",
        "Persuading listeners",
        "Staying on topic, on the conversation now",
        "Distinguishing topics (e.g. in meetings)",
        "Listening actively and fully",
        "Listening for sub-texts (between the lines)",
        "Listening for “gold” (solutions, greatness)",
        "Standing in the shoes of others, empathy",
        "Responding empathically (not tit-for-tat)",
        "Utilizing and integrating people's already-existing agenda, enrollment",
        "Managing the team (e.g. building alignment; recognizing what each brings)",
        "Giving effective, constructive feedback",
        "Appreciating and acknowledging people"
    ],
    "Vision": [
        "Creating the big picture (future context)",
        "Creating and articulating a future for and/or with people",
        "Standing in the future, accomplishment",
        "Staying committed to the outcome while unattached to the pathway",
        "Standing for people’s future potential",
        "Completing past unfinished business",
        "Bringing vision back when it’s lost it in the day-to-day",
        "Sustaining an environment of vision, momentum, breakthrough"
    ],
    "Strategy": [
        "Strategic thinking",
        "Identifying what’s missing, blockages",
        "Revealing hidden opportunities for synergies, convergence",
        "Creating a shared understanding of the levers for achieving the vision",
        "Aligning on a shared strategic intent",
        "Defining outcome and input KPIs",
        "Aligning on 3-5 strategic objectives",
        "Designing a structure for fulfillment (e.g. feasibility, milestones, budgets)",
        "Defining and deploying resources for implementation and success",
        "Innovating processes",
        "Seeing what could go wrong, finding key success factors",
        "Saying No to legacy or low-level activities"
    ],
    "Action": [
        "Making powerful promises and requests (measurable, with deadlines)",
        "Managing yourself (focus, intentions & priorities, tracking results, eliminating clutter, managing for results)",
        "Managing the details, not dropping anything out",
        "Designing and maintaining displays that pull for the right actions and results",
        "Running productive & decisive meetings",
        "Managing the project’s energy & operating states",
        "Debriefing projects and leveraging the learnings",
        "Completing work and conversations",
        "Being self-reliant and resourceful",
        "Being fearless; undaunted by 'no'",
        "Being powerful in the face of breakdowns; turning breakdowns into breakthroughs",
        "Never, ever, ever giving up"
    ]
    /*"Sustainability": [
        "Firing yourself, working yourself out of the current job",
        "Coaching skills; effective empowerment of people",
        "Supporting people’s development",
        "Building, sustaining a team of leaders",
        "Transferring knowledge, tools, technologies",
        "Establishing sustainable structures, systems, processes",
        "Installing capability-building systems",
        "Having systems that work while you’re not there",
        "Sustainable supply chains",
        "Taking care of yourself (mental, emotional, physical)",
        "Creating a balance of work, rest, play, relationships",
        "Creating a culture of leadership"
    ]*/
};


const AssessmentForm = () => {
    const { answers, setAnswers } = useContext(AssessmentContext);
    const [unanswered, setUnanswered] = useState([]);
    const navigate = useNavigate();
    const [randomQuestionsByCategory, setRandomQuestionsByCategory] = useState({});

    useEffect(() => {
        const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        const categories = Object.keys(questionsByCategory);
        const shuffledCategories = shuffleArray([...categories]);

        // Updated array of unique question counts.
        const uniqueQuestionCounts = [3, 4, 5, 3, 5];

        // Assign the question counts to the shuffled categories.
        const questionsCountByCategory = {};
        shuffledCategories.forEach((category, index) => {
            questionsCountByCategory[category] = uniqueQuestionCounts[index];
        });

        const randomizedQuestionsByCategory = Object.fromEntries(
            Object.entries(questionsByCategory).map(([category, questions]) => {
                const shuffledQuestions = shuffleArray(questions);
                return [category, shuffledQuestions.slice(0, questionsCountByCategory[category])];
            })
        );

        setRandomQuestionsByCategory(randomizedQuestionsByCategory);
    }, []);
    

    const handleChange = (value, question, category) => {
        setAnswers(prev => ({ 
            ...prev, 
            [question]: { answer: value, category } 
        }));
        setUnanswered(prev => prev.filter(q => q !== question));
    };

    const handleSubmit = () => {
        const unansweredQuestions = Object.keys(randomQuestionsByCategory).flatMap(category => {
            return randomQuestionsByCategory[category].filter(question => !answers[question]);
        });
    
        if (unansweredQuestions.length > 0) {
            setUnanswered(unansweredQuestions);
            message.warning('Please answer all the questions.');
        } else {
            navigate('/get-email');
        }
    };
    

    const columns = [
        {
            title: 'Evaluate',
            dataIndex: 'question',
            key: 'question',
            width: '70%',  // Add this
            render: (question) => (
                <Tag color={unanswered.includes(question) ? 'red' : 'blue'} style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                {question}
              </Tag>
            ),
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
            width: '30%',
            render: (_, record) => (
                <Form.Item name={record.question}>
                    <Select onChange={(value) => handleChange(value, record.question, record.category)}style={{ minWidth: '15ch' }}>
                        <Option value={1}>Non-existent</Option>
                        <Option value={2}>Weak</Option>
                        <Option value={3}>Moderate</Option>
                        <Option value={4}>Competent</Option>
                        <Option value={5}>Masterful</Option>
                    </Select>
                </Form.Item>
            ),
        },
    ];

    return (
        <Form onFinish={handleSubmit}>
            {Object.entries(randomQuestionsByCategory).map(([category, questions]) => (
                <div key={category}>
                    <Divider><Title level={4}>{category}</Title></Divider>
                    <Table
                        columns={columns}
                         dataSource={questions.map(question => ({ question, category }))}
                        pagination={false}
                    />
                </div>
            ))}
            <Form.Item>
                <Button type="primary" htmlType="submit">See Your Leadership Profile</Button>
            </Form.Item>
        </Form>
    );
};

export default AssessmentForm;