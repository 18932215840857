import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import WelcomePage from './components/WelcomePage';
import AssessmentForm from './components/AssessmentForm';
import GetEmail from './components/GetEmail';
import { AssessmentProvider } from './components/AssessmentContext';
import ProfileResult from './components/ProfileResult';
import { ConfigProvider } from 'antd'; // Add this line



function App() {
  const [answers, setAnswers] = useState({});
  const [email, setEmail] = useState("");

  return (
    <ConfigProvider> 
      <Router>
        <AssessmentProvider value={{ answers, setAnswers, email, setEmail }}>
          <Routes>
            <Route path='/' element={<WelcomePage />} />
            <Route path='/assessment' element={<AssessmentForm />} />
            <Route path="/get-email" element={<GetEmail />} />
            <Route path='/profile-result' element={<ProfileResult />} />
          </Routes>
        </AssessmentProvider>
      </Router>
   </ConfigProvider> 
  );
}

export default App;
 